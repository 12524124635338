/********************************************general******************************************************/

body {
  margin: 0;
  font-weight: bold;
  font-family: Arial;
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: right;
}

/* page title */
h1 {
  color: #095d83;
  font-style: oblique;
  font-weight: bold;
  font-size: 3.5rem;
  padding-bottom: 15px;
  font-family: "Heebo";
}

/* sub page title & body titles */

h2 {
  color: #095d83;
  font-size: 2rem;
  font-weight: bold;
  font-family: "Heebo";
}

/* recommendation writing */
h4 {
  /* padding-bottom: 10px; */
  font-size: 1.5rem;
  color: #095d83;
  font-weight: bold;
  font-family: "Heebo";
}

/* taskbar & recommendation */
h5 {
  color: #17749f;
  padding: 10px 20px 0px 20px;
  /* text-decoration: none; */
  font-weight: bold;
  font-size: 1rem;
  font-family: "Heebo";
}

/* copyright */
h6 {
  color: #40514e;
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Heebo";
}

p {
  color: #40514e;
  margin: 0 auto 0 auto;
  line-height: 1.5;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: "Heebo";
}

@media (min-width: 600px) and (max-width: 950px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 0.9rem;
  }
  p {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.7rem;
  }
  p {
    font-size: 0.8rem;
  }
}

/********************************************task bar******************************************************/
.taskbar {
  background-color: #def4f4;
  padding: 0.5% 0 0.3% 0;
  /*position: fixed;
    text-align: center;
  */
}

.logo {
  width: 150px;
}

.message {
  text-align: center;
  color: black;
  font-size: 1.5rem;
}

a:hover {
  color: #50a1c5;
  text-decoration: none;
}

h5:hover {
  color: #50a1c5;
  text-decoration: none;
}

.register {
  margin-left: 20px;
  margin-right: 20px;
}

/********************************************openning******************************************************/
.openning {
  background-color: #eaf6f6;
  text-align: center;
  /*padding-bottom: 20px;*/
  padding-top: 50px;
}

.TitlePicture1 {
  /*padding: 20px;*/
  /*display: inline-block;*/
  /* height:  500px;  */
  width: 80%;
  max-width: 500px;
}

.TitlePicture2 {
  /*padding: 20px;*/
  /*display: inline-block;*/
  /* height:  500px;  */
  width: 40%;
  max-width: 300px;
}

.TitlePicture3 {
  /*padding: 20px;*/
  /*display: inline-block;*/
  /* height:  500px;  */
  width: 80%;
  max-width: 750px;
}

/********************************************services******************************************************/
.services {
  margin: 60px 0;
  text-align: center;
}

.dot {
  border-style: none;
  border-top-style: dotted;
  border-color: #bbf4f4;
  border-width: 5px;
  /* width: 8%; */
  display: flex;
  justify-content: center;
}

.line {
  border: 1px solid #17749f;
}

/* for home page */
.container1 {
  /* width: 55%; */
  padding-left: 5%;
  padding-right: 5%;
}

/* for about page */
.container2 {
  /* width: 80%; */
  padding-left: 5%;
  padding-right: 5%;
}

/* for about page */
.container3 {
  /* width: 95%; */
  padding-left: 3%;
  padding-right: 3%;
}

.text {
  text-align: right;
}

.bullets {
  padding-right: 330px;
}

/********************************************recommendation******************************************************/

.recommendations {
  padding: 3% 15%;
  text-align: center;
  background-color: #eaf6f6;
  min-height: 500px;
}

.recPic {
  width: 100%;
  min-width: 800px;
}

/********************************************begin now******************************************************/
.user {
  text-align: center;
  line-height: 2;
  padding: 3% 0;
}

.butn {
  background: #11cdd4;
  background-image: -webkit-linear-gradient(top, #11cdd4, #11999e);
  background-image: -moz-linear-gradient(top, #11cdd4, #11999e);
  background-image: -ms-linear-gradient(top, #11cdd4, #11999e);
  background-image: -o-linear-gradient(top, #11cdd4, #11999e);
  background-image: linear-gradient(to bottom, #11cdd4, #11999e);
  -webkit-border-radius: 8;
  -moz-border-radius: 8;
  border-radius: 8px;
  font-family: Arial;
  color: #ffffff;
  font-size: 15px;
  padding: 7px 10px 7px 10px;
  text-decoration: none;
}

.butn:hover {
  background: #30e3cb;
  background-image: -webkit-linear-gradient(top, #30e3cb, #2bc4ad);
  background-image: -moz-linear-gradient(top, #30e3cb, #2bc4ad);
  background-image: -ms-linear-gradient(top, #30e3cb, #2bc4ad);
  background-image: -o-linear-gradient(top, #30e3cb, #2bc4ad);
  background-image: linear-gradient(to bottom, #30e3cb, #2bc4ad);
  text-decoration: none;
}

/********************************************contact******************************************************/
.contact {
  background-color: #eaf6f6;
  text-align: center;
  padding: 25px 10px 10px 10px;
  /* margin-top: 20px; */
}

.inline {
  display: inline-block;
}

.list {
  list-style-type: disc;
  padding-right: 40px;
}

.faq {
  background-color: #eaf6f6;
  padding: 3%;
}
.borders {
  border: none;
  outline: none;
}

.borders:focus {
  outline: 0;
}
/********************************************courses card pricing******************************************************/

.pricing-column {
  padding: 3% 2%;
}

.card-color {
  background-color: #eaf6f6;
}
.card-color:hover {
  cursor: pointer;
}

.pricing {
  padding: 4% 4%;
  text-align: center;
  background-color: #eaf6f6;
}

.courses {
  text-align: center;
}
.video {
  text-align: center;
  /* display: block;
    width: 320px;
    height: 240px;
      margin: 0 auto; */
}

/********************************************payment bar******************************************************/
.color1 {
  background-color: #f3f0f0;
  padding: 10px 0px 0px 0;

  /*position: fixed;
    text-align: center;
  */
}

.color2 {
  background-color: #ebe6e6;
  padding: 10px 0px 0px 0;
  /* padding: 10 10 20 20; */
}

.back {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 40px;
}

iframe {
  width: 90%;
  max-width: 700px;

  height: 300px;
}

/********************************************payment bar******************************************************/

.demo {
  color: "blue";
}

/* .post-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
    } */

.post-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
/*   
  .post-container iframe {
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
  } */

/* 
  .iframe-fb-container {
    text-align: center;
    width:350px;
    height:200px;
    max-width: 100%;
    display:inline-block;
}

.iframe-fb {
    width: 300px;
    height: 240px;
    overflow: hidden;
    max-width: 100%;
}  */

.resp-container {
  position: center;
  /* overflow: hidden; */
  /* padding-top: 56.25%; */
}

.person-color {
  color: #17749f;
}

.box {
  /* background-color: #EAF6F6; */
  border-radius: 20px 20px;
  padding: 0px 0px;
  border-color: #40514e;
  border: 30px 30px;
  box-shadow: 1px -1px 1px 2px;
}

.spacing {
  display: inline;
  padding: 20px;
}

.link {
  text-decoration: none;
}

.link:hover {
  color: #50a1c5;
  cursor: pointer;
  text-decoration: none;
}

.numbering {
  color: #095d83;
  /* background-color: #17749f; */
}

.container6 {
  /* width: 55%; */
  padding: 0% 10%;
}

html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}
